<template>
    <v-container fluid>
      <v-card>
        <v-toolbar color="secondary" flat>
          <v-toolbar-title>Payment Terms</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <!-- <h3>Under Active Development</h3> -->
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
</template>

<script>
export default {
  data: () => ({
    search: ""
  }),
  methods: {},
  created() {}
};
</script>